export const environment = {
  production: false,
  tidIssuer: 'https://stage.id.trimblecloud.com',
  tidScope: 'openid TrimbleCCX-test-v01',
  tidResponseType: 'code',
  tidClientId: '0a07bccd-8219-4671-bc13-3ccc9b6b2887',
  tidAuthorizeUrl: 'https://stage.id.trimblecloud.com/oauth/authorize',
  tidLogoutUrl: 'https://stage.id.trimblecloud.com/oauth/logout',
  tidLogoutRedirectUrl: 'https://interstellar-ccx.trimbleag.team/auth/logout',
  tidRedirectUrl: 'https://interstellar-ccx.trimbleag.team/login/callback',
  tidTokenUrl: 'https://stage.id.trimblecloud.com/oauth/token',
  ccxAuthUrl: '/api/auth/token',
  ccxUrl: '/api/v1',
  coreApiUrl: '/api/v1/core-gql',
  coreProtocolBeefUrl: '/api/v1/beef-gql',
    coreProtocolNerpUrl: '/api/v1/nerp-gql',
  umbracoHostUrl: 'https://interstellar-ccx-content-trimbleag.azurewebsites.net',
  graphQlCmsApiUrl: '/api/cms/graphql',
  rootNavContentKey: 'b036b48b-4fe9-40ff-a849-2477b7a8b90e',
  webSocketHost: 'interstellar-ccx-api-trimbleag.azurewebsites.net',
    dashboards: {
        beef: {
            reportId: '4aa30bcb-491e-4c75-9e41-1bde983b9cfd',
            workspaceId: '3e164978-b786-4b4b-9f72-894648e6edd5'
        }
    }
};
