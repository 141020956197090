import { Component, OnDestroy, OnInit, Optional, Host, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { documentManagerActions, documentManagerSelectors } from './index';
import { combineLatestWith, debounceTime, filter, first, map, Observable, of, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { DocumentManagerFile, DocumentManagerState } from './+state/document-manager.reducer';
import { KebabMenuAction, KebabMenuCellEditorComponent, KebabMenuParams } from '../ccx-ag-datagrid/cellEditor/kebab-menu-cell-editor.component';
import { GridOptions, IRowNode } from 'ag-grid-community';
import { ActivatedRoute } from '@angular/router';
import { State } from 'src/app/state/app.state';
import { BinaryFilterOperation, ProjectsByProtocolGQL } from '../../generated/operations-core-graphql';
import { DialogService } from '../dialog/dialog.service';
import { CdkScrollable } from '@angular/cdk/overlay';
import { ContentService } from '../../services/content.service';
import { ToastrService } from 'ngx-toastr';
import { LinkToProjectCellEditor } from '../ccx-ag-datagrid/cellEditor/link-to-project-cell-editor.component';
import { GridColumnConfigurationAg } from '../ccx-grid/ccx-grid.component';
import { documentStatusCellEditor } from '../ccx-ag-datagrid/cellEditor/document-status-cell-editor.component';
import { KebabMenuCellRendererComponent } from '../ccx-ag-datagrid/cellEditor/kebab-menu-cell-renderer.component';
import { DataSource } from 'src/app/@core/components/data-source';
import { data } from 'src/app/Components/data-table/DataTableOptions';
import { localizeGrid } from '../../utils';
import { openSelectedActionDialog } from '../../functions/dialog-functions';
import { TranslocoService } from '@jsverse/transloco';
import { ChangeLogModalComponent } from './change-log-modal/change-log-modal.component';
import { LinkToProjectShellComponent } from './link-to-project-shell/link-to-project-shell.component';
import { EditDocumentShellComponent } from './edit-document-shell/edit-document-shell.component';
import { DocumentService } from '../../services/document.service';
import { UploadFileShellComponent } from './upload-file-shell/upload-file.shell.component';


export class SelectedProjectItem {
    id!: string;
    name!: string;
    year!: number;
}

export interface OrganizationDocumentRow extends data {
    documentId: string;
    type: string;
    name: string;
    fileName: string;
    size: string;
    status: string;
    lastUpdated: string;
    id: number;
    blobIdentifier: string;
}
@Component({
    selector: 'app-document-manager-shell',
    templateUrl: './document-manager.shell.component.html',
    styleUrls: ['./document-manager.shell.component.scss']
})
export class DocumentManagerShellComponent implements OnInit, OnDestroy {

    isWorking$!: Observable<boolean>
    files$!: Observable<DocumentManagerFile[]>;
    filterCounter: number = 0;
    projectId!: string;
    isWorking = this.store.selectSignal(documentManagerSelectors.selectIsWorking);
    gridOptions: GridOptions<any>;
    private onDestroy$ = new Subject<void>();
    searchByInput = new Subject<string>();
    rowsSelected!: any;
    canDelete = false;

    @Input() organizationId!: string | undefined;
    @Input() protocolId!: string | undefined;

    @Output() cellLinkClicked = new EventEmitter<any>();
    @Output() onToggleFilter = new EventEmitter();


    constructor(
        private store: Store<State>,
        readonly dialogService: DialogService,
        private langSrc: TranslocoService,
        private contSrvc: ContentService,
        private toastr: ToastrService,
        private docSrvc: DocumentService,
        @Optional() @Host() scrollContainer: CdkScrollable
    ) {
        this.gridOptions = {
            context: {
                componentParent: this,
                parentElementRef: scrollContainer
            }
        };
    }

    selectedUtilitySection = '';
    @Input() OrganizationDocumentDataSource!: DataSource<OrganizationDocumentRow>;

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnInit(): void {
        this.store.select(documentManagerSelectors.selectProtocolFileUploadDialogOpen)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((open) => {
                if (open) {
                    this.dialogService.create(UploadFileShellComponent, {
                        organizationId: this.organizationId,
                        protocolId: this.protocolId,
                        projectId: this.projectId
                    });
                }
            });


        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(documentManagerSelectors.selectEditDocumentDialogOpen),)
            .subscribe((open) => {
                if (open) {
                    this.dialogService.create(EditDocumentShellComponent, {
                        organizationId: this.organizationId,
                        protocolId: this.protocolId,
                        projectId: this.projectId
                    });
                }
            });

        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(documentManagerSelectors.selectLinkToProjectDialogOpen),
        )
            .subscribe((open) => {
                if (open) {
                    this.dialogService.create(LinkToProjectShellComponent, {});
                }
            });

        this.store.pipe(
            takeUntil(this.onDestroy$),
            select(documentManagerSelectors.selectChangeLogModalDialogState),
            combineLatestWith(this.store.select(documentManagerSelectors.selectChangeLogModalDocumentId))
        )
            .subscribe(([open, docId]) => {
                if (open && docId) {
                    this.dialogService.create(ChangeLogModalComponent, {
                        documentId: docId
                    });
                }
            });

        this.isWorking$ = this.store.select(documentManagerSelectors.selectIsWorking);
        this.files$ = this.store.select(documentManagerSelectors.selectFiles);


        this.OrganizationDocumentDataSource.onDataRequest$
            .pipe(takeUntil(this.onDestroy$),
                combineLatestWith(this.store.select(documentManagerSelectors.selectOrganizationSelectedProject)),
                filter(([, selectedProject]) => !!selectedProject)
            )
            .subscribe(([params, selectedProject]) => {

                if (this.protocolId && this.organizationId && selectedProject) {
                    this.projectId = selectedProject.id;
                    this.store.dispatch(
                        documentManagerActions.protocolOrganizationDocumentLoad({
                            input: {
                                input: {
                                    protocolId: this.protocolId,
                                    organizationId: this.organizationId,
                                    projectId: selectedProject.id
                                },
                                ...params
                            }
                        })
                    );
                }

            });
        this.store.select(documentManagerSelectors.selectOrganizationDocumentList)
            .pipe(takeUntil(this.onDestroy$),
                filter(e => {
                    return e == null;
                })
            ).subscribe(e => {
                this.OrganizationDocumentDataSource.onPageChange(0);
            });


        this.searchByInput.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(500)
        ).subscribe(e => {
            this.OrganizationDocumentDataSource.gridSearchBy({
                exprGrp: [{
                    grpOp: BinaryFilterOperation.And,
                    expr: {
                        column: 'name',
                        operator: 'contains',
                        value: e
                    }
                }]
            });
        });


        this.store.dispatch(documentManagerActions.getProtocolDefinitionFiles({
            variable: {
                where: {
                    expr: {
                        column: 'Id',
                        operator: '=',
                        value: this.protocolId || ''
                    }
                }
            }
        }));
    }

    gridSearchBy(evt: CustomEvent<string>) {
        this.searchByInput.next(evt.detail);
    }

    getSelectedRows(grid: any) {
        this.rowsSelected = grid.api.getSelectedRows();
        this.canDelete = this.rowsSelected.length != 0;
    }


    onSelectionChanged(grid: any) {
        this.getSelectedRows(grid);
    }
    onModelUpdated(grid: any) {
        this.getSelectedRows(grid);
    }

    filterToggled() {
        console.log('uy');
        this.onToggleFilter.emit();
    }

    onClickUpload() {
        this.fileUploadPopup();
    }

    onCellLinkClick($event: IRowNode<OrganizationDocumentRow>) {
        this.cellLinkClicked.emit($event);
    }




    fileUploadPopup() {
        if (this.protocolId && this.organizationId && this.projectId) {
            this.store.dispatch(documentManagerActions.protocolFileUploadDialogOpen({ value: true }));
        } else {
            this.toastr.info(this.langSrc.translate('First select a project to upload files'));
        }
    }

    onPanelClose() {

    }
}

export const organizationDocumentGridConfiguration: GridColumnConfigurationAg[] = [
    {
        field: 'id',
        headerName: 'id',
        hide: true,
        columnSourceId: 'id',
        id: 'id',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },
    {
        field: 'blobIdentifier',
        headerName: 'blobIdentifier',
        hide: true,
        columnSourceId: 'blobIdentifier',
        id: 'blobIdentifier',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },
    {
        field: 'documentId',
        headerName: 'documentId',
        hide: true,
        columnSourceId: 'documentId',
        id: 'documentId',
        type: 'leftAligned',
        filterType: 'none',
        typeValue: 'string'
    },
    {
        field: 'type',
        headerName: 'Type',
        columnSourceId: 'type',
        id: 'type',
        filterType: 'text',
        typeValue: 'string',
        minWidth: 250,
        maxWidth: 250,
        sort: 'asc',
        wrapText: true,
        autoHeight: true,
        cellStyle: { 'line-height': '1.8em', 'padding': '0.5em var(--ag-cell-horizontal-padding)', 'word-break': 'break-word' },

    },
    {
        field: 'name',
        headerName: 'Document Name',
        columnSourceId: 'name',
        id: 'name',
        filterType: 'text',
        typeValue: 'string',
        minWidth: 250,
        maxWidth: 250,
        wrapText: true,
        autoHeight: true,
        cellStyle: { 'line-height': '1.8em', 'padding': '0.5em 0px', 'word-break': 'break-all' },

    },
    {
        field: 'fileName',
        headerName: 'File Name',
        columnSourceId: 'fileName',
        id: 'fileName',
        filterType: 'text',
        typeValue: 'string',
        cellRenderer: LinkToProjectCellEditor,
        wrapText: true,
        autoHeight: true,
        cellStyle: { 'line-height': '1.8em', 'padding': '0.5em 0px', 'word-break': 'break-all' },

    },
    {
        field: 'size',
        headerName: 'Size',
        columnSourceId: 'size',
        id: 'size',
        filterType: 'text',
        typeValue: 'string',
        maxWidth: 200,
    },
    {
        field: 'status',
        headerName: 'Status',
        columnSourceId: 'status',
        id: 'status',
        cellRenderer: documentStatusCellEditor,
        filterType: 'select',
        typeValue: 'string',
        type: 'centerAligned'
    },
    {
        field: 'lastUpdated',
        headerName: 'Last Updated',
        columnSourceId: 'lastUpdated',
        id: 'lastUpdated',
        filterType: 'dateTime',
        typeValue: 'datetime',
    }
];